.csv-table-container {
    margin-top: 20px;
  }
  
  .table-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .csv-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .csv-table th, .csv-table td {
    border: 1px solid #ddd;
    padding: 6px; /* Adjusted padding */
    font-size: 14px; /* Adjusted font size */
    text-align: left;
  }
  
  .csv-table th {
    background-color: #f2f2f2;
  }
  
  .toggle-button {
    text-align: right;
  }