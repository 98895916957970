.imgbox {
    display: grid;
    padding: 20px;
    height: 100%;
}
.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.image-container {
    display: flex;
    justify-content: space-between;
}

.image-container img {
    width: 33%;
    height: auto;
}


